:root {
  --ui-button-bkg-color: #c9c8c856;
  --ui-panel-bkg-color: #31313154;
  --min-window-width: 1000px;
  --min-window-height: 666px;
  --font-color: #faebd7;
}

/* @import url("https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap"); */

/* @font-face {
  font-family: copperplateGL;
  src: local("./fonts/coprgtl.ttf");
} */

html,
body {
  margin: 0;
  height: 100%;
}

body {
  background-color: black;
  /* font-family: "Copperplate Gothic Light"; */
  /* font-family: copperplateGL; */
  /* font-family: "IM Fell English SC", serif; */
  font-family: "Cormorant SC", serif;
  font-size: large;
}

canvas {
  width: 100%;
  height: 100%;
  /* max-width: calc(100vh * 3 / 2); */

  z-index: 3;
}

a {
  color: chocolate;
}

#canvas-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

#ui-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-width: var(--min-window-width);
  min-height: var(--min-window-height);
}

main {
  width: 100%;
  min-width: var(--min-window-width);
  height: 100%;
  min-height: var(--min-window-height);
}

button {
  background-color: var(--ui-button-bkg-color);
  color: var(--font-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18pt;
  /* font-family: "Copperplate Gothic Light"; */
  /* font-family: "IM Fell English SC", serif; */
  font-family: "Cormorant SC", serif;
  font-weight: bold;
  border: #ffffffd3;
  border: 1px;
  border-style: solid;
  border-radius: 8px;
  min-width: 42px;
  height: 42px;
  margin: 7px;
  filter: drop-shadow(4px 4px 3px black);
}

.small-label {
  margin: 0;
  color: var(--font-color);
}

.btn-small-font {
  font-size: 15pt;
}

h2,
h3 {
  margin: 0;
  color: var(--font-color);
  /* font-family: copperplateGL; */
  /* font-family: "IM Fell English SC", serif; */
}

.lr-margin {
  margin-left: 5px;
  margin-right: 5px;
}

/* CONTAINERS **********************************************/
#top-container {
  display: flex;
  justify-content: space-between;
  flex-basis: 3rem;
  z-index: 5;
}
#mid-container {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  z-index: 5;
  pointer-events: none;
  background: transparent;
}
#bottom-container {
  flex-basis: 5rem;
  z-index: 5;
  display: flex;
  justify-content: center;
}

/* PANELS ************************************************* */
#left-panel {
  height: 420px;
  background-color: var(--ui-panel-bkg-color);
  flex-basis: 5rem;
  border-radius: 0px 15px 15px 0px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

#right-panel {
  height: 420px;
  background-color: var(--ui-panel-bkg-color);
  flex-basis: 5rem;
  border-radius: 15px 0px 0px 15px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#bottom-panel {
  border-radius: 15px 15px 0px 0px;
  background-color: var(--ui-panel-bkg-color);
  height: 100%;
  display: flex;
  align-items: center;
  width: 1000px;
}

#dice-count-panel {
  display: flex;
  background-color: var(--ui-panel-bkg-color);
  border-radius: 0px 0px 15px 0px;
  padding: 4px;
}

#roll-dice-panel {
  display: flex;
  background-color: var(--ui-panel-bkg-color);
  border-radius: 0px 0px 0px 15px;
  padding-left: 6px;
}

.panel-shadow {
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.719);
  backdrop-filter: blur(4);
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#result-header {
  text-align: center;
}

#dice-count-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dice-img {
  width: 42px;
  height: 42px;
}

.rotate90 {
  transform: rotate(90deg);
}

td {
  text-align: center;
  font-size: 20px;
  min-width: 30px;
}

table {
  color: var(--font-color);
}

.narrow {
  width: 42px;
}

.vertical {
  writing-mode: vertical-lr;
  text-orientation: sideways;
}

#load-splash-screen {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
}

#splash-screen-content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#load-progress-text {
  color: var(--font-color);
  text-align: center;
}

#load-background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to right,
      rgba(10, 1, 49, 0.678),
      rgba(53, 20, 1, 0.589)
    ),
    url("../img/cathedralBackground.png");
  background-color: rgb(44, 46, 54);
  background-size: cover;
  filter: blur(15) grayscale(50%);
}

#load-dh-logo {
  width: 300px;
  filter: invert(93%) sepia(11%) saturate(500%) hue-rotate(334deg)
    brightness(102%) contrast(96%);
}

.text-panel {
  text-align: center;
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 20px;
}

.help-icon {
  position: absolute;
  margin-top: 100%;
  left: 100%;
  /* z-index: 6; */
  /* right: 0; */
  bottom: 0;
  width: 50px;
  height: 50px;
}

/* Help Modal Window */

#help-btn {
  /* position: fixed; */
  position: absolute;
  width: 40px;
  height: 40px;
  right: 5px;
  bottom: 5px;
  z-index: 6;
}

#help-modal-container {
  display: none;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 600px;
  height: 600px;
  transform: translate(-50%, -50%);

  border-radius: 15px;
  background-color: var(--ui-panel-bkg-color);
  backdrop-filter: blur(4px);

  color: var(--font-color);

  /* debug stuff */
  box-sizing: border-box;
  border-color: var(--font-color);
  border-style: solid;
  border-width: 1px;
}

#help-panel-tl {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 250px;

  background-color: var(--ui-button-bkg-color);
  border-radius: 15px 0px 15px 0px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

#help-panel-tr {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 250px;

  background-color: var(--ui-button-bkg-color);
  border-radius: 0px 15px 0px 15px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

#help-panel-r {
  position: absolute;
  top: 50%;
  right: 0;
  height: 300px;
  width: 200px;
  transform: translate(0%, -50%);
  padding: 0 10px;

  background-color: var(--ui-button-bkg-color);
  border-radius: 15px 0px 0px 15px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

#help-panel-btm {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 120px;
  width: 450px;
  transform: translate(-50%, 0%);

  background-color: var(--ui-button-bkg-color);
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#help-panel-l {
  position: absolute;
  top: 50%;
  left: 0;
  height: 300px;
  width: 150px;
  transform: translate(0%, -50%);
  padding: 0 10px;

  background-color: var(--ui-button-bkg-color);
  border-radius: 0px 15px 15px 0px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

#help-panel-ctr {
  position: absolute;
  top: 65%;
  left: 48%;
  height: 200px;
  width: 200px;
  transform: translate(-50%, -50%);
  padding: 0 10px;
}

#dh-help-logo {
  width: 250px;
  filter: invert(93%) sepia(11%) saturate(500%) hue-rotate(334deg)
    brightness(102%) contrast(96%);

  position: absolute;
  left: 50%;
  top: 105px;
  transform: translate(-50%, -50%);
}

#dh-logo {
  width: 150px;
  filter: invert(93%) sepia(11%) saturate(500%) hue-rotate(334deg)
    brightness(102%) contrast(96%) opacity(50%);

  position: absolute;
  left: 50%;
  top: 5px;
  transform: translate(-50%, 0%);
  z-index: 10;
}
