:root {
  --ui-button-bkg-color: #c9c8c856;
  --ui-panel-bkg-color: #31313154;
  --min-window-width: 1000px;
  --min-window-height: 666px;
  --font-color: #faebd7;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #000;
  font-family: Cormorant SC, serif;
  font-size: large;
}

canvas {
  width: 100%;
  height: 100%;
  z-index: 3;
}

a {
  color: #d2691e;
}

#canvas-container {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

#ui-main {
  height: 100%;
  width: 100%;
  min-width: var(--min-window-width);
  min-height: var(--min-window-height);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

main {
  width: 100%;
  min-width: var(--min-window-width);
  height: 100%;
  min-height: var(--min-window-height);
}

button {
  background-color: var(--ui-button-bkg-color);
  color: var(--font-color);
  text-align: center;
  min-width: 42px;
  height: 42px;
  filter: drop-shadow(4px 4px 3px #000);
  border: 1px solid;
  border-radius: 8px;
  margin: 7px;
  font-family: Cormorant SC, serif;
  font-size: 18pt;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.small-label {
  color: var(--font-color);
  margin: 0;
}

.btn-small-font {
  font-size: 15pt;
}

h2, h3 {
  color: var(--font-color);
  margin: 0;
}

.lr-margin {
  margin-left: 5px;
  margin-right: 5px;
}

#top-container {
  z-index: 5;
  flex-basis: 3rem;
  justify-content: space-between;
  display: flex;
}

#mid-container {
  z-index: 5;
  pointer-events: none;
  background: none;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#bottom-container {
  z-index: 5;
  flex-basis: 5rem;
  justify-content: center;
  display: flex;
}

#left-panel {
  height: 420px;
  background-color: var(--ui-panel-bkg-color);
  pointer-events: all;
  border-radius: 0 15px 15px 0;
  flex-direction: column;
  flex-basis: 5rem;
  align-items: center;
  padding-top: 10px;
  display: flex;
}

#right-panel {
  height: 420px;
  background-color: var(--ui-panel-bkg-color);
  pointer-events: all;
  border-radius: 15px 0 0 15px;
  flex-direction: column;
  flex-basis: 5rem;
  justify-content: center;
  display: flex;
}

#bottom-panel {
  background-color: var(--ui-panel-bkg-color);
  height: 100%;
  width: 1000px;
  border-radius: 15px 15px 0 0;
  align-items: center;
  display: flex;
}

#dice-count-panel {
  background-color: var(--ui-panel-bkg-color);
  border-radius: 0 0 15px;
  padding: 4px;
  display: flex;
}

#roll-dice-panel {
  background-color: var(--ui-panel-bkg-color);
  border-radius: 0 0 0 15px;
  padding-left: 6px;
  display: flex;
}

.panel-shadow {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 5px 5px 10px 2px #000000b7;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
}

#result-header {
  text-align: center;
}

#dice-count-text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.dice-img {
  width: 42px;
  height: 42px;
}

.rotate90 {
  transform: rotate(90deg);
}

td {
  text-align: center;
  min-width: 30px;
  font-size: 20px;
}

table {
  color: var(--font-color);
}

.narrow {
  width: 42px;
}

.vertical {
  writing-mode: vertical-lr;
  text-orientation: sideways;
}

#load-splash-screen {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#splash-screen-content {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#load-progress-text {
  color: var(--font-color);
  text-align: center;
}

#load-background {
  z-index: 1;
  width: 100%;
  height: 100%;
  filter: blur(15px) grayscale(50%);
  background-color: #2c2e36;
  background-image: linear-gradient(to right, #0a0131ad, #35140196), url("cathedralBackground.9cc26bb9.png");
  background-size: cover;
  position: absolute;
}

#load-dh-logo {
  width: 300px;
  filter: invert(93%) sepia(11%) saturate(500%) hue-rotate(334deg) brightness(102%) contrast(96%);
}

.text-panel {
  text-align: center;
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 20px;
}

.help-icon {
  width: 50px;
  height: 50px;
  margin-top: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
}

#help-btn {
  width: 40px;
  height: 40px;
  z-index: 6;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

#help-modal-container {
  z-index: 10;
  width: 600px;
  height: 600px;
  background-color: var(--ui-panel-bkg-color);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  color: var(--font-color);
  box-sizing: border-box;
  border-color: var(--font-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#help-panel-tl {
  height: 60px;
  width: 250px;
  background-color: var(--ui-button-bkg-color);
  text-align: center;
  border-radius: 15px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#help-panel-tr {
  height: 60px;
  width: 250px;
  background-color: var(--ui-button-bkg-color);
  text-align: center;
  border-radius: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

#help-panel-r {
  height: 300px;
  width: 200px;
  background-color: var(--ui-button-bkg-color);
  border-radius: 15px 0 0 15px;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}

#help-panel-btm {
  height: 120px;
  width: 450px;
  background-color: var(--ui-button-bkg-color);
  border-radius: 15px 15px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

#help-panel-l {
  height: 300px;
  width: 150px;
  background-color: var(--ui-button-bkg-color);
  border-radius: 0 15px 15px 0;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
}

#help-panel-ctr {
  height: 200px;
  width: 200px;
  padding: 0 10px;
  position: absolute;
  top: 65%;
  left: 48%;
  transform: translate(-50%, -50%);
}

#dh-help-logo {
  width: 250px;
  filter: invert(93%) sepia(11%) saturate(500%) hue-rotate(334deg) brightness(102%) contrast(96%);
  position: absolute;
  top: 105px;
  left: 50%;
  transform: translate(-50%, -50%);
}

#dh-logo {
  width: 150px;
  filter: invert(93%) sepia(11%) saturate(500%) hue-rotate(334deg) brightness(102%) contrast(96%) opacity(50%);
  z-index: 10;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%);
}

/*# sourceMappingURL=index.66bd3212.css.map */
